import {
    SetAdvisorUrlAction,
    SetAreaAction,
    SetAreaCodeAction,
    SetAreaCodeBagAction,
    SetAreaManualAction,
    SetBuildingTypeAction,
    SetBuildingTypeGroupAction,
    SetHasCompletedAction,
    SetHasVisitedAction,
    SetHouseNumberAction,
    SetHouseNumberAdditionAction,
    SetProductChoiceAction,
    SetPromotionAction,
    SetZipCodeAction
} from "@js/actions/informationActions";

import {
    SET_ADVISOR_URL,
    SET_AREA,
    SET_AREA_CODE,
    SET_AREA_CODE_BAG,
    SET_AREA_MANUAL,
    SET_BUILDING_TYPE,
    SET_BUILDING_TYPE_GROUP,
    SET_HAS_COMPLETED,
    SET_HAS_VISITED,
    SET_HOUSE_NUMBER,
    SET_HOUSE_NUMBER_ADDITION,
    SET_PRODUCT_CHOICE,
    SET_PROMOTION,
    SET_ZIP_CODE
} from "@js/actionTypes/informationActionTypes";

/**
 * @const setAdvisorUrl
 */
export const setAdvisorUrl = (advisorUrl: string): SetAdvisorUrlAction => ({
    payload: {
        advisorUrl: advisorUrl
    },
    type: SET_ADVISOR_URL
});

/**
 * @const setArea
 */
export const setArea = (area: string): SetAreaAction => ({
    payload: {
        area: area
    },
    type: SET_AREA
});

/**
 *
 * @param productChoice
 */
export const setProductChoice = (productChoice: string): SetProductChoiceAction => ({
    payload: {
        productChoice: productChoice
    },
    type: SET_PRODUCT_CHOICE
});

/**
 * @const setPromotion
 */
export const setPromotion = (promotion: object): SetPromotionAction => ({
    payload: {
        promotion: promotion
    },
    type: SET_PROMOTION
});

/**
 * @const setAreaCode
 */
export const setAreaCode = (areaCode: string): SetAreaCodeAction => ({
    payload: {
        areaCode: areaCode
    },
    type: SET_AREA_CODE
});

/**
 * @const setAreaCodeBag
 */
export const setAreaCodeBag = (areaCodeBag: string): SetAreaCodeBagAction => ({
    payload: {
        areaCodeBag: areaCodeBag
    },
    type: SET_AREA_CODE_BAG
});

/**
 * @const setAreaManual
 */
export const setAreaManual = (areaManual: boolean): SetAreaManualAction => ({
    payload: {
        areaManual: areaManual
    },
    type: SET_AREA_MANUAL
});

/**
 * @const setBuildingTypeGroup
 */
export const setBuildingTypeGroup = (buildingTypeGroup: string): SetBuildingTypeGroupAction => ({
    payload: {
        buildingTypeGroup: buildingTypeGroup
    },
    type: SET_BUILDING_TYPE_GROUP
});

/**
 * @const setBuildingType
 */
export const setBuildingType = (buildingType: string): SetBuildingTypeAction => ({
    payload: {
        buildingType: buildingType
    },
    type: SET_BUILDING_TYPE
});

/**
 * @const setHasCompleted
 */
export const setHasCompleted = (hasCompleted: boolean): SetHasCompletedAction => ({
    payload: {
        hasCompleted: hasCompleted
    },
    type: SET_HAS_COMPLETED
});

/**
 * @const setHasVisited
 */
export const setHasVisited = (hasVisited: boolean): SetHasVisitedAction => ({
    payload: {
        hasVisited: hasVisited
    },
    type: SET_HAS_VISITED
});

/**
 * @const setHouseNumber
 */
export const setHouseNumber = (houseNumber: string): SetHouseNumberAction => ({
    payload: {
        houseNumber: houseNumber
    },
    type: SET_HOUSE_NUMBER
});

/**
 * @const setHouseNumberAddition
 */
export const setHouseNumberAddition = (houseNumberAddition: string): SetHouseNumberAdditionAction => ({
    payload: {
        houseNumberAddition: houseNumberAddition
    },
    type: SET_HOUSE_NUMBER_ADDITION
});

/**
 * @const setZipCode
 */
export const setZipCode = (zipCode: string): SetZipCodeAction => ({
    payload: {
        zipCode: zipCode
    },
    type: SET_ZIP_CODE
});