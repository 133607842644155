import React from 'react';
import {useSelector} from "react-redux";
import StoreState from "@js/store/StoreState";

export type DisplayProductChoiceProps = {
    inputText: string;
}

const DisplayProductChoice = (props: DisplayProductChoiceProps) => {
    const {
        inputText,
        ...restProps
    } = props;

    const productChoice = useSelector((storeState: StoreState) => storeState.information.productChoice);
    const regex = new RegExp('productChoice', 'g');

    return (
        <span dangerouslySetInnerHTML={{ __html: inputText.replace(regex, productChoice === 'customAdvice' ? "maatwerkadvies" : "energielabel") }} />
    );
}

export default DisplayProductChoice;